import React from 'react'

const Logo = () => {
    return (
        <>
            <div className='container-logo'>
                <h2>
                    <span className='inicial'>M</span>atías
                    {/* <br className='d-sm-none'></br> */}
                    <span className='inicial ms-2 ms-md-3'>C</span>ontreras
                </h2>
                <h3 className='me-sm-auto'>FullStack Developer</h3>
            </div>
        </>
    )
}

export default Logo