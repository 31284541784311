import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";

const PoliticaPrivacidad = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <>
      <Container xl className="mb-5">
        <div className='blog-titulo'>
          <h1>Política de privacidad</h1>
        </div>
      </Container>

      <Container xl>
        <hr />
        <h2>1. OBJETO</h2>
        <p>Esta Política de Privacidad tiene por objeto dar a conocer el modo en el que recabo, trato y protejo los datos personales que Usted como Usuario me ha facilitado, por medio de mi página web o blog (en adelante Sitio Web) y decida libremente si desea que los trate. Asimismo, pretende dar cumplimiento al Reglamento General de protección de datos personales 2016/679 (en adelante RGPD) y a la Ley Orgánica 3/2018 de protección de datos personales y garantía de los derechos digitales (en adelante, LOPDGDD).</p>

        <h2>2. IDENTIFICACIÓN DEL RESPONSABLE / PRESTADOR DE SERVICIOS DE LA SOCIEDAD DE LA INFORMACIÓN</h2>
        <p>Nombre / Razón social: Matías Contreras Alessandro Recanatti <br />
          NIE / NIF: X3829511B <br />
          Domicilio social: Calle Cuevas de Vinromá, 3 4 4 <br />
          Correo electrónico: matiascontr@gmail.com <br />
          Teléfono: 663 20 33 22 <br />
          Nombre de dominio: mattcoco.github.io <br />
          Actividad: Programador</p>

        <h2>3. TRATAMIENTO DE DATOS PERSONALES</h2>
        <p>Los datos personales identificativos y de contacto que me facilite por medio de mis formularios de contacto, correo electrónico, etc., serán tratados de forma confidencial y solo por personal autorizado por mí. Todas las categorías y tipos de datos personales tratados quedarán debidamente identificados en las correspondientes actividades de tratamiento titularidad mía.</p>

        <h2>4. FINALIDAD</h2>
        <p>Con carácter general, el tratamiento de datos personales realizado por mí tiene como finalidad el mantenimiento de la relación con diferentes colectivos de personas, como mis clientes, proveedores, usuarios de mi web/blog o redes sociales e interesados en general que contacten conmigo por medio de mis formularios web, correo electrónico, telefónica o personalmente.</p>
        <p>Dependiendo de dicha relación, el tratamiento de sus datos obedece a diferentes finalidades, ya que puede ser para atender solicitudes de información, quejas, reclamaciones y otras que proactivamente me hagan llegar los interesados, también para cumplir con mis obligaciones contractuales, comerciales, legales o para la atención al ejercicio de derechos.</p>
        <p>Si es Usted cliente, sus datos personales serán tratados con la finalidad de mantener la relación negocial, contractual o comercial, ya sea para la prestación de mis servicios, para la gestión contable y de facturación y otros fines a los que me vea obligado para dar cumplimiento a las leyes a las que estemos sujetos y para cumplir con dicha relación.</p>
        <p>No envío publicidad sin el consentimiento previo de los interesados, salvo a mis clientes con relación vigente y siempre sobre los servicios prestados.</p>
        <p>Si es un potencial cliente o un solicitante de información, trataré sus datos exclusivamente para atender sus solicitudes, incluyendo en mi contestación información comercial relacionada con su solicitud.</p>
        <p>Si es Usted un proveedor, sus datos personales serán tratados con la única finalidad de mantener la relación comercial, ya sea para la prestación de servicios o compra de bienes, para la gestión contable y otros fines a los que me vea obligado para dar cumplimiento a dicha relación.</p>
        <p>También se pueden tratar datos con diferentes finalidades al aceptar la instalación de mis cookies si las llegase a haber. Para más información, visite mi Política de Cookies en el momento que exista.</p>
        <p>Las finalidades que motivan el tratamiento de datos personales quedarán debidamente identificadas en las correspondientes actividades de tratamiento titularidad mía.</p>

        <h2>5. LEGITIMACIÓN</h2>
        <p>El tratamiento de sus datos personales por mi parte se realiza con las siguientes bases legitimadoras:</p>
        <p>a. Su consentimiento expreso, libre, informado e inequívoco, puesto que le informo de la presente política de privacidad que tras la lectura de esta y de estar conforme, puede voluntariamente autorizarme al tratamiento de sus datos mediante la marcación de las casillas dispuestas a tal efecto en mis formularios. A este respecto, le informo que en cualquier momento puede cambiar de opinión y retirar su consentimiento dirigiéndose a mi dirección postal o por correo electrónico.</p>
        <p>b. El cumplimiento de la relación comercial o contractual con su persona, en caso de ser cliente, proveedor.</p>
        <p>c. Dar cumplimiento a mis obligaciones legales. En el caso de que el usuario sea menor de 14 años, será necesario contar con el consentimiento de los padres, tutores o representante legal, para tratar sus datos. El usuario es el único responsable de la veracidad de los datos que me remite.</p>

        <h2>6. CONSERVACIÓN DE DATOS</h2>
        <p>Los datos personales tratados se conservarán durante el tiempo necesario para cumplir con la finalidad para la que se recaban, durante el tiempo que me exijan las leyes y mientras sea necesario para depurar posibles responsabilidades derivadas de los tratamientos citados en las finalidades.</p>
        <p>En el caso de solicitudes de empleo, se conservarán durante el plazo máximo de 1 año o hasta que el interesado nos solicite suprimir sus datos.</p>

        <h2>7. ELABORACIÓN DE PERFILES</h2>
        <p>No elaboro perfiles utilizando sus datos personales, pero en el caso de hacerlo, se le informará y solicitará permiso previo para hacerlo. Del mismo modo, tiene el derecho a oponerse a este tipo de tratamiento en cualquier momento.</p>

        <h2>8. CESIÓN DE DATOS</h2>
        <p>Por norma general, no cedo datos personales a terceros, aunque pueden darse casos donde esto sea necesario. Si es un cliente y está interesado en la adquisición de mis servicios, sus datos personales podrán ser cedidos a otros proveedores que necesiten sus datos para llevar a cabo el cumplimiento de mis servicios. Asimismo, sus datos podrán ser cedidos a entidades terceras por obligación legal (e.g., Agencia Tributaria), o en aquellos casos y entidades necesarias para prestarle mis servicios o pagar facturas (e.g., entidades bancarias).</p>
        <p>Si es un proveedor, sus datos personales podrán ser cedidos a entidades terceras por obligación legal (e.g., Agencia Tributaria), o en aquellos casos y entidades necesarios para prestarle mis servicios o pagar facturas (e.g., entidades bancarias). Asimismo, sus datos personales en calidad de cliente o proveedor podrán ser tratados por ciertos proveedores a los que delego alguna de mis obligaciones (e.g., asesores contables). Todos ellos se han comprometido, mediante un contrato de encargado del tratamiento, a cumplir con las mismas medidas de seguridad implantadas por mí, así como a someterse al deber de secreto y confidencialidad sobre los datos personales tratados, entre otras obligaciones en materia de protección de datos personales.</p>
        <p>Si es Usted un solicitante de información o usuario de mi sitio web, sus datos no se cederán a entidades terceras, salvo que legalmente me vea obligado a ello.</p>
        <p>En caso de cesiones a otras entidades o a otros países fuera del EEE, le informaré y solicitaré su consentimiento previo y expreso.</p>


        <h2>9. MEDIDAS DE SEGURIDAD</h2>
        <p>He implantado todas las medidas técnicas y organizativas necesarias para proteger los datos personales tratados, evitando su pérdida, robo o uso no autorizado. Dichas medidas se han creado en función del tipo de datos tratados y las finalidades que motivan dicho tratamiento. Estas son verificadas periódicamente en mis controles de cumplimiento de la normativa de protección de datos personales.</p>

        <h2>10. DERECHOS DE LOS INTERESADOS</h2>
        <p>Usted tiene derecho a conocer si estoy tratando sus datos personales; por tanto, tiene derecho a acceder a sus datos, rectificarlos si son inexactos o solicitar su supresión cuando los datos ya no sean necesarios, siempre que sea legalmente posible. También puede ejercer su derecho de oposición, limitación o portabilidad si así lo estima conveniente, incluso podrá revocar su consentimiento y para ejercer sus derechos deberá hacerlo por escrito a matiascontr@gmail.com, acompañando una copia de su DNI o documento equivalente para facilitarme su identificación. Si quien desea ejercer sus derechos es menor de 14 años, podrá hacerlo a través de sus padres, tutores o representante legal.</p>
        <p>Si desea trasladar alguna sugerencia o consulta sobre el tratamiento de sus datos personales, puede contactarme a mi correo matiascontr@gmail.com, indicando el nombre de mi empresa o el nombre comercial.</p>
        <p>Le informo que Usted tiene derecho a realizar una reclamación ante la Agencia Española de Protección de Datos en: C/Jorge Juan, 6, 28001 Madrid o en www.aepd.es.</p>

        <h2>11. COMPROMISO CON LA PROTECCIÓN DE DATOS PERSONALES</h2>
        <p><strong>- Ámbito de aplicación</strong></p>
        <p>Mi compromiso con la protección de los datos personales será de obligado cumplimiento para mí en todo momento.</p>
        <p><strong>- Objeto</strong></p>
        <p>He establecido unos protocolos de actuación para el tratamiento de datos personales, de acuerdo con lo dispuesto en la normativa europea y española de protección de datos.</p>
        <p><strong>- Principios</strong></p>
        <p>Licitud, Lealtad, Transparencia, Minimización de los datos, Exactitud, Limitación del plazo de conservación, Integridad, Confidencialidad y Responsabilidad activa.</p>
        <p><strong>- Categoría especial de datos</strong></p>
        <p>Se prohíbe el tratamiento de datos personales que revelen el origen étnico o racial, las opiniones políticas, las convicciones religiosas o filosóficas, la afiliación sindical, el tratamiento de datos genéticos o biométricos, datos relativos a la salud o datos relativos a la orientación sexual, salvo en las excepciones autorizadas legalmente y con el consentimiento previo del interesado.</p>
        <p><strong>- Derechos de los interesados</strong></p>
        <p>Los interesados gozarán del derecho de acceso a sus datos personales, así como a rectificarlos cuando sean inexactos, suprimirlos cuando ya no sean necesarios o no se desee su tratamiento, limitarlos a ciertos tratamientos, tener la posibilidad de recibir sus datos con facilidad y en formatos estructurados y de uso común del responsable, así como que sus datos sean utilizados para la elaboración de perfiles y oponerse al tratamiento en cualquier momento.</p>
        <p><strong>- Registro de Actividades, Evaluación de Impacto y Medidas de Seguridad</strong></p>
        <p>Llevaré a cabo un registro de las actividades de tratamiento y analizaré los fines del tratamiento, categorías de los interesados y de datos, destinatarios, transferencias internacionales, plazos de conservación, etc., para evaluar los riesgos del tratamiento e implantar las medidas de seguridad necesarias para garantizar la confidencialidad, integridad y disponibilidad de los datos personales.</p>
        <p><strong>- Control</strong></p>
        <p>Monitorizo cualquier publicación realizada por los órganos de control competentes y otras entidades europeas y españolas relacionadas con la Normativa de protección de datos, con el fin de cumplir con esta normativa en todo momento.</p>

        <h2>13. ACTUALIZACIÓN DE ESTA POLÍTICA</h2>
        <p>Mi entidad se reserva el derecho a la modificación de esta Política sin previo aviso. Es por ello que recomiendo consultarla cada vez que visite mi web.</p>
        <p>Actualizado el 21 de noviembre de 2023</p>

        <p>Esta política de privacidad refleja mi compromiso con la protección de sus datos personales y el cumplimiento de las normativas vigentes. Si tiene alguna pregunta o inquietud sobre cómo se tratan sus datos, no dude en ponerse en contacto conmigoa través del correo electrónico matiascontr@gmail.com.</p>

        <p>Recuerde que su privacidad es importante y que estoy comprometido a garantizar la seguridad y confidencialidad de la información que me proporciona.</p>
        <br />
      </Container>

    </>
  );
};

export default PoliticaPrivacidad;
