import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";

const AvisoLegal = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <>
      <Container xl className="mb-5">
        <div className='blog-titulo'>
          <h1>AVISO LEGAL</h1>
        </div>
      </Container>

      <Container xl>
        <hr />
        <h2>1. INFORMACIÓN LEGAL</h2>
        <p>De acuerdo con lo estipulado en el artículo 10 de la Ley 34/2002, del 11 de julio, relativa a los Servicios de la Sociedad de la Información y de Comercio Electrónico, conocida como LSSI-CE, proporciono de manera clara y precisa a los usuarios, quienes son los destinatarios de los servicios, así como a las autoridades competentes, la siguiente información en relación a mi calidad de proveedor de servicios de la sociedad de la información:</p>
        <p>Nombre / Razón social: <strong>Matías Contreras Alessandro Recanatti</strong> <br />
          NIE / NIF: <strong>X3829511B</strong> <br />
          Domicilio social: <strong>Calle Cuevas de Vinromá, 3 4 4</strong> <br />
          Correo electrónico: <strong>matiascontr@gmail.com</strong> <br />
          Teléfono: <strong>663 20 33 22</strong> <br />
          Nombre de dominio: <strong>mattcoco.github.io</strong> <br />
          Actividad: <strong>Programador</strong> <br />
        </p>

        <h2>2. CONDICIONES GENERALES DE USO</h2>
        <p>El acceso a mi Página Web/Blog, en adelante referida como el Sitio Web, se ofrece de manera libre y gratuita para todas las personas, sin necesidad de suscribirse o contratar algún servicio de mi entidad.</p>
        <p>Cualquier usuario que utilice o navegue por mi sitio web acepta implícitamente las condiciones de uso y otras especificaciones mencionadas en este Aviso Legal.</p>
        <p>Te advierto que me reservo el derecho de modificar mi Aviso Legal sin previo aviso, por lo que recomiendo consultar este documento cada vez que visites mi sitio.</p>
        <p>Todos los visitantes de mi sitio web están obligados a usarlo de buena fe, respetando la ley y cumpliendo con las obligaciones establecidas en este Aviso Legal. Además, te comprometes a utilizar tus credenciales de acceso de manera apropiada y te abstendrás de introducir contenidos injuriosos o calumniosos en mi sitio web. También te comprometes a no bloquear ni dañar, de manera temporal o permanente, las funcionalidades, contenidos o infraestructura de la web que puedan afectar su uso.</p>
        <p>En caso de que incumplas total o parcialmente las condiciones de uso o cualquier otra especificación de este Aviso Legal, me reservo el derecho de denegar el acceso al sitio web sin previo aviso y de tomar medidas legales para proteger mis intereses.</p>

        <h2>3. PROPIEDAD INTELECTUAL</h2>
        <p>Este sitio web contiene elementos sujetos a la protección de los derechos de propiedad intelectual e industrial, de acuerdo con la legislación española. Estos elementos incluyen el código fuente, los diseños gráficos, las imágenes, las fotografías, los sonidos, las animaciones, el software, los textos, así como la información y los contenidos. En ningún momento declaro propiedad de los trabajos expuestos de la sección "Portafolio".</p>
        <p>Queda prohibida la reproducción total o parcial del sitio web o del logotipo, así como su manipulación informática, distribución, difusión, modificación, transformación, descompilación o cualquier otro derecho legalmente reconocido a su titular, sin mi autorización previa y por escrito.</p>
        <p>Tienes permitido utilizar el material presente en este sitio web exclusivamente para uso personal y privado. Se prohíbe su utilización con fines profesionales, comerciales o para llevar a cabo actividades ilícitas. Todos los derechos relacionados con la propiedad intelectual están expresamente reservados por mi entidad.</p>
        <p>Tomaré las medidas necesarias para asegurar el cumplimiento de las condiciones mencionadas y el uso apropiado de los contenidos en mis páginas web y logotipo, incluyendo la toma de medidas legales, tanto civiles como penales, en caso de infracción o incumplimiento de estos derechos por tu parte.</p>

        {/* <h2>4. USO DE COOKIES Y MODIFICACIONES EN EL SITIO WEB</h2>
        <p>Mi sitio web hace uso de cookies y tecnologías similares que almacenan y recuperan información durante tu navegación. Estas tecnologías tienen diversos propósitos, como el reconocimiento del usuario, la recopilación de información sobre tus hábitos de navegación, la entrega de publicidad personalizada y la adaptación de la presentación del contenido.</p>
        <p>Para obtener información detallada sobre los tipos de cookies utilizados en mi sitio web, te invito a consultar mi Política de Cookies.</p>
        <p>Me reservo el derecho de modificar la información contenida en mi sitio web, así como su configuración y presentación, sin previo aviso.</p> */}

        <h2>4. COMPROMISOS CON EL USUARIO</h2>
        <p>A través de este medio, me comprometo a no llevar a cabo publicidad engañosa. En consecuencia, los errores formales o numéricos que puedan encontrarse en el contenido de las diversas secciones de esta web, debido a un mantenimiento o actualización incompleta o defectuosa, no se considerarán publicidad engañosa. Me comprometo a corregir estos errores tan pronto como tenga conocimiento de ellos.</p>
        <p>Asimismo, me comprometo a no enviar comunicaciones comerciales sin identificarlas como tales y sin tu consentimiento previo, de acuerdo con la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio electrónico. Se exceptúa de esta consideración la información enviada a mis clientes, destinada a mantener la relación contractual existente entre el cliente y mi entidad, así como a llevar a cabo las tareas de información y otras actividades relacionadas con el servicio contratado.</p>

        <h2>5. COMPROMISO DE PROTECCIÓN DE DATOS PERSONALES</h2>
        <p>Me comprometo a tratar tus datos personales de conformidad con el Reglamento General de Protección de Datos Personales (RGPD 2016/679) y la Ley Orgánica 3/2018, de 5 de diciembre, de protección de datos personales y garantía de los derechos digitales (LOPDPGDD). Para obtener más detalles, te invito a consultar mi Política de Privacidad.</p>

        <h2>6. INCUMPLIMIENTOS Y RESPONSABILIDADES</h2>
        <p>No asumo responsabilidad por el incumplimiento de cualquier normativa aplicable por tu parte al acceder al presente sitio web o utilizar la información contenida en el mismo.</p>
        <p>No seré responsable de los daños y perjudicios, independientemente de su naturaleza, derivados del uso de la información, contenidos y programas presentes en este sitio web.</p>
        <p>No me hago responsable del uso indebido que terceros puedan hacer de nombres de marca, nombres de producto o marcas comerciales que no sean propiedad de mi entidad y aparezcan en el sitio web. Además, no me hago responsable de la integridad, veracidad o legalidad del contenido de los enlaces e hipertexto a sitios web ajenos accesibles desde el presente sitio web, ya que no están bajo mi control.</p>
        <p>En resumen, eres el único responsable del uso de los servicios, contenidos, enlaces e hipertexto incluidos en este sitio web.</p>

        <h2>7. LEGISLACIÓN APLICABLE Y FUERO JURISDICCIONAL</h2>
        <p>En todos los asuntos legales relacionados con los sitios web dependientes de mi entidad, se aplicará la legislación española. Los Juzgados y Tribunales competentes para resolver los conflictos derivados del uso de este sitio web serán determinados de acuerdo con la competencia territorial correspondiente a mi calidad de proveedor de servicios de la sociedad de la información.</p>
        <br />
        <br />
      </Container>
    </>
  );
};

export default AvisoLegal;
