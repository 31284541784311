import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";

const PoliticaProteccionDatos = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <>
      <Container xl className="mb-5">
        <div className='blog-titulo'>
          <h1 className="text-center">
            POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES
          </h1>
        </div>
      </Container>

      <Container xl>
        <hr />
        <h2>1. RESPONSABLE DEL TRATAMIENTO</h2>
        <p>Yo, Matías Contreras Alessandro Recanatti, soy el responsable del tratamiento de los datos personales recabados a través de esta web. Puedes contactarme mediante los siguientes datos:</p>
        <p>Nombre / Razón social: Matías Contreras Alessandro Recanatti <br />
          NIE / NIF: X3829511B <br />
          Domicilio social: Calle Cuevas de Vinromá, 3 4 4 <br />
          Correo electrónico: matiascontr@gmail.com <br />
          Teléfono: 663 20 33 22 <br />
          Nombre de dominio: mattcoco.github.io <br />
          Actividad: Programador</p>

        <h2>2. FINALIDAD DEL TRATAMIENTO</h2>
        <p>Los datos personales proporcionados a través de esta web serán tratados con las siguientes finalidades:</p>
        <ul>
          <li>Gestionar las consultas y solicitudes realizadas a través de los formularios de contacto.</li>
          <li>Enviar información comercial relacionada con mis productos y servicios, siempre que hayas otorgado el consentimiento previo.</li>
          <li>Cumplir con obligaciones legales y contractuales.</li>
        </ul>

        <h2>3. LEGITIMACIÓN DEL TRATAMIENTO</h2>
        <p>La base legal para el tratamiento de tus datos personales es:</p>
        <ul>
          <li>Tu consentimiento expreso, otorgado al completar los formularios de contacto y marcar la casilla de aceptación de esta política de privacidad o de protección de datos.</li>
          <li>La ejecución de un contrato o la toma de medidas a petición tuya antes de celebrar un contrato.</li>
          <li>El cumplimiento de obligaciones legales aplicables a mi responsabilidad del tratamiento.</li>
        </ul>

        <h2>4. CONSERVACIÓN DE DATOS</h2>
        <p>Los datos personales se conservarán durante el tiempo necesario para cumplir con la finalidad para la que fueron recabados y para cumplir con las obligaciones legales. En caso de consentimiento para recibir comunicaciones comerciales, los datos se conservarán hasta que se revoque el consentimiento.</p>

        <h2>5. DERECHOS DE LOS USUARIOS</h2>
        <p>Tienes derecho a:</p>
        <ul>
          <li>Acceder a tus datos personales y obtener información sobre su tratamiento.</li>
          <li>Rectificar datos inexactos o incompletos.</li>
          <li>Suprimir los datos cuando no sean necesarios para la finalidad que motivó su recogida.</li>
          <li>Oponerte al tratamiento de tus datos, salvo por motivos legítimos o el ejercicio o la defensa de posibles reclamaciones.</li>
          <li>Limitar el tratamiento de los datos en determinadas circunstancias.</li>
          <li>La portabilidad de los datos, es decir, recibir los datos en un formato estructurado y de uso común, así como transmitirlos a otro responsable del tratamiento cuando sea técnicamente posible.</li>
          <li>Revocar el consentimiento otorgado para el tratamiento de tus datos personales.</li>
        </ul>

        <h2>6. SEGURIDAD DE LOS DATOS</h2>
        <p>He implementado medidas técnicas y organizativas adecuadas para proteger tus datos personales, evitando su pérdida, robo o acceso no autorizado.</p>

        <h2>7. CAMBIOS EN LA POLÍTICA DE PROTECCIÓN DE DATOS</h2>
        <p>Esta política de protección de datos puede ser modificada en cualquier momento. Se recomienda revisarla periódicamente para estar informado de cualquier cambio.</p>
        <p>Actualizado el 21 de noviembre de 2023</p>
        <p>Si tienes alguna pregunta o solicitud relacionada con esta política de protección de datos, no dudes en ponerte en contacto conmigo a través de los datos de contacto proporcionados anteriormente. Tu privacidad es importante, y estoy comprometido a garantizar la seguridad y confidencialidad de la información que me proporcionas.</p>
        <br />
      </Container>

    </>
  );
};

export default PoliticaProteccionDatos;
