import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap'
import emailjs from '@emailjs/browser';
import { ReCAPTCHA } from "react-google-recaptcha"
import Fade from 'react-reveal/Fade';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';



const Servicios = () => {
  // link should what comes afterwards /servicios/ in the url
  const { link } = useParams();

  // Scroll to the section that matches the link in the url taking into account the offset of the navbar
  const disenoweb = useRef(null);
  const marketing = useRef(null);
  const ecommerce = useRef(null);
  const redes = useRef(null);
  useEffect(() => {
    if (link === 'disenoweb') {
      disenoweb.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (link === 'marketing') {
      marketing.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (link === 'ecommerce') {
      ecommerce.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (link === 'redes') {
      redes.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      window.scrollTo(0, 0);
    }
  }, [link])

  const { quiero } = useParams();
  // Form controller
  const [isSent, setIsSent] = useState(false)
  // const handleSubmit = () => { setIsSent(true) }
  const form = useRef();
  //se supone que esto sirve para resetear el captcha después de un check
  const captchaRef = useRef(null);
  const sendEmail = (e) => {
    e.preventDefault();
    //se supone que esto sirve para resetear el captcha después de un check
    const token = captchaRef.current.getValue();
    captchaRef.current.reset()
    emailjs.sendForm('contact_service', 'contacto_landing', form.current, 'FZ6-0g0WtEyveYD_X')
      .then((result) => {
        //! Eliminar al final
        console.log(result.text);
        setIsSent(true)
      }, (error) => {
        console.log(error.text);
      });
  };


  return (
    <div>
      <Fade cascade>
        <div className='servicios-fondo'>
          <h1>Trabajemos juntos</h1>
          <div className='servicios-contenedor'>
            <div className='servicios-panel izq retraso-uno'>
              <span className='servicios-panel-texto izq'>Crea de sitios web <span className='negrita'>atractivos y funcionales</span></span>
              <span className='servicios-panel-icono der'><i className="fa-solid fa-pen-ruler"></i></span>
            </div>
            <div className='servicios-panel der retraso-dos'>
              <span className='servicios-panel-icono izq'><i className="fa-brands fa-sellsy"></i></span>
              <span className='servicios-panel-texto'>Mejora la <span className='negrita'>visibilidad</span> en los motores de búsqueda</span>
            </div>
          </div>
          <div className='servicios-contenedor '>
            <div className='servicios-panel izq retraso-tres'>
              <span className='servicios-panel-texto izq'>Vende de <span className='negrita'>productos</span> y <span className='negrita'>servicios</span> en línea</span>
              <span className='servicios-panel-icono der'><i className="fa-solid fa-credit-card"></i></span>
            </div>
            <div className='servicios-panel der retraso-cuatro'>
              <span className='servicios-panel-icono izq'><i className="fa-solid fa-bullhorn"></i></span>
              <span className='servicios-panel-texto'>Promociona tu negocio en el <span className='negrita'>entorno digital</span></span>
            </div>
          </div>
        </div>
      </Fade>


      <div className='servicios-contenido'>
        <Container className="py-5">
          <Fade>
            <h1 className='text-center mb-4'>Esto es lo que puedo hacer por ti</h1>
          </Fade>
          <Fade>
            <Row className="my-5">
              <Col sm={12} lg={6} className=''>
                <h3 className='mihtres' ref={disenoweb} >
                  <span className='icono-servicio-container-sm-md d-lg-none' style={{ backgroundColor: "#FF0099" }}>
                    <i className="fa-solid fa-pen-ruler"></i>
                  </span>
                  Diseños estéticos
                </h3>
                <p>La estética de una web es crucial para una experiencia <strong>atractiva</strong> y <strong>memorable</strong>.</p>
                <p>Un diseño cuidadosamente planificado destaca en un mercado saturado y atrae a más visitantes. La tipografía debe ser legible y coherente, el color utilizado de manera estratégica y la <strong>disposición intuitiva</strong>. La navegación debe ser clara y fácil de usar, lo que crea una presencia en línea exitosa y memorable.</p>
              </Col>
              <Col sm={12} lg={{ span: 5, offset: 1 }} className="d-none d-lg-flex justify-content-center align-items-center">
                <div className='icono-servicio-container' style={{ backgroundColor: "#FF0099" }}>
                  <h1><i className="fa-solid fa-pen-ruler"></i></h1>
                </div>
              </Col>
            </Row>
          </Fade>

          <Fade>
            <Row className='my-5'>
              <Col sm={12} lg={6} className=''>
                <h3 className='mihtres' ref={marketing}>
                  <span className='icono-servicio-container-sm-md d-lg-none' style={{ backgroundColor: "#00C750" }}>
                    <i className="fa-solid fa-credit-card"></i>
                  </span>
                  Sitios E-commerce
                </h3>
                <p>El E-commerce es una industria <strong>en crecimiento</strong> que ofrece una gran oportunidad para vender productos y servicios en línea.</p>
                <p>La plataforma de comercio electrónico debe ser confiable, fácil de usar y con una experiencia de compra <strong>sin problemas</strong>. Además, se requiere un diseño atractivo, un catálogo de productos fácil de navegar y un proceso de pago seguro y sencillo.</p>
                <p>En resumen, el E-commerce es una oportunidad rentable y conveniente para hacer crecer tu negocio en línea.</p>
              </Col>
              <Col sm={12} lg={{ span: 5, offset: 1 }} className="d-none d-lg-flex justify-content-center align-items-center">
                <div className='icono-servicio-container' style={{ backgroundColor: "#00C750" }}>
                  <h1><i className="fa-solid fa-credit-card"></i></h1>
                </div>
              </Col>
            </Row>
          </Fade>

          <Fade>
            <Row className='my-5'>
              <Col sm={12} lg={6} className=''>
                <h3 className='mihtres' ref={ecommerce}>
                  <span className='icono-servicio-container-sm-md d-lg-none' style={{ backgroundColor: "#FFE600" }}>
                    <i className="fa-brands fa-sellsy"></i>
                  </span>
                  Marketing digital
                </h3>
                <p>El Marketing Digital es esencial para el éxito en línea. <strong>SEO y SEM</strong> son técnicas que mejoran la visibilidad en los resultados de búsqueda.</p>
                <p>SEO optimiza el sitio web para aparecer más alto en los resultados orgánicos, y SEM utiliza publicidad pagada. Una estrategia efectiva combina ambas técnicas, optimiza el sitio web y la investigación de palabras clave, y ejecuta campañas publicitarias estratégicas.</p>
                <p>Con la combinación adecuada, puedes atraer <strong>más tráfico</strong> y <strong>clientes potenciales</strong>.</p>
              </Col>
              <Col sm={12} lg={{ span: 5, offset: 1 }} className="d-none d-lg-flex justify-content-center align-items-center">
                <div className='icono-servicio-container' style={{ backgroundColor: "#FFE600" }}>
                  <h1><i className="fa-brands fa-sellsy"></i></h1>
                </div>
              </Col>
            </Row>
          </Fade>

          <Fade>
            <Row className='my-5'>
              <Col sm={12} lg={6} className=''>
                <h3 className='mihtres' ref={redes}>
                  <span className='icono-servicio-container-sm-md d-lg-none' style={{ backgroundColor: "#00c0ff" }}>
                    <i className="fa-solid fa-bullhorn"></i>
                  </span>
                  Gestión de redes
                </h3>
                <p>La gestión de redes sociales es clave para conectarte con la <strong>audiencia en línea</strong> de tu negocio.</p>
                <p>Publica contenido relevante y de alta calidad de manera constante, interactúa con los usuarios y utiliza herramientas de análisis para medir el rendimiento. Con una gestión efectiva de redes sociales, podrás construir una presencia en línea sólida y <strong>fortalecer las relaciones</strong> con tus clientes.</p>
              </Col>
              <Col sm={12} lg={{ span: 5, offset: 1 }} className="d-none d-lg-flex justify-content-center align-items-center">
                <div className='icono-servicio-container' style={{ backgroundColor: "#00c0ff" }}>
                  <h1><i className="fa-solid fa-bullhorn"></i></h1>
                </div>
              </Col>
            </Row>
          </Fade>

          <Fade>
            <Row className='my-5'>
              <Col sm={12} lg={6} className=''>
                <h3 className='mihtres'>
                  <span className='icono-servicio-container-sm-md d-lg-none' style={{ backgroundColor: "#a200ff" }}>
                    <i className="fa-solid fa-computer"></i>
                  </span>
                  Software a medida
                </h3>
                <p>Al optar por soluciones a medida, te aseguras de que cada aspecto del software se ajuste perfectamente a tus requisitos.</p>
                <p>Desde aplicaciones internas hasta sistemas de gestión complejos, el software personalizado ofrece una respuesta única y eficiente.</p>
                <p>Con un enfoque centrado en tus objetivos, garantizo que el software desarrollado no solo cumpla con tus expectativas, sino que también <strong>impulse el crecimiento</strong> y la <strong>productividad</strong> de tu empresa.</p>
              </Col>
              <Col sm={12} lg={{ span: 5, offset: 1 }} className="d-none d-lg-flex justify-content-center align-items-center">
                <div className='icono-servicio-container' style={{ backgroundColor: "#a200ff" }}>
                  <h1><i className="fa-solid fa-computer"></i></h1>
                </div>
              </Col>
            </Row>
          </Fade>




        </Container>
      </div >



      <div className='servicios-contenido otro-fondo'>
        <Container fluid className='p-0 m-0'>
          <Fade>
            <h1 className='text-center bg-white mb-4 p-md-5 text-dark'>Trabajemos juntos</h1>
          </Fade>
        </Container>

        <Container className="">
          <Row className="my-5">
            <Col md={{ span: 6 }} >
              <div className='bg-white bg-opacity-90 p-4 mb-3 rounded'>
                <h3 style={{ fontSize: "28px" }}>Enfocado a la atención cliente</h3>
                <p>Entiendo la importancia de la comunicación clara y frecuente con mis clientes.</p>
                <p>Me aseguro de que siempre estén actualizados sobre el progreso del proyecto y de que sus inquietudes sean atendidas en todo momento.</p>
              </div>

              <div className='bg-white bg-opacity-90 p-4 mb-3 rounded'>
                <h3 style={{ fontSize: "28px" }}>Trabajo a medida</h3>
                <p>Cada proyecto es único, y trato de asegurarme de que el trabajo que hago para mis clientes sea personalizado y adaptado a sus necesidades específicas.</p>
                <p>No ofrezco soluciones pre-fabricadas.</p>
              </div>

              <div className='bg-white bg-opacity-90 p-4 mb-3 rounded'>
                <h3 style={{ fontSize: "28px" }}>Multidisciplina</h3>
                <p>Tengo experiencia trabajando con varios lenguajes de programación, lo que me permite adaptarme a las necesidades de cada proyecto y ofrecer soluciones creativas y personalizadas.</p>
              </div>

              <div className='bg-white bg-opacity-90 p-4 mb-3 rounded'>
                <h3 style={{ fontSize: "28px" }}>Enfocado a la experiencia usuario</h3>
                <p>No solo me preocupo por el aspecto visual de un sitio web, sino que también me aseguro de que la experiencia del usuario sea excelente.</p>
                <p>Me aseguro de que el sitio sea fácil de navegar y utilizar para los visitantes, lo que aumenta la probabilidad de que permanezcan en el sitio y se conviertan en clientes.</p>
              </div>

              <div className='bg-white bg-opacity-90 p-4 mb-3 rounded'>
                <h3 style={{ fontSize: "28px" }}>Tarifas flexibles</h3>
                <p>Entiendo que cada cliente tiene diferentes presupuestos. Por eso, ofrezco tarifas personalizadas y flexibles para cada proyecto.</p>
                <p>Esto me permite trabajar con una amplia gama de clientes y brindar soluciones de calidad que se adapten a su presupuesto.</p>
                <p>Mi objetivo es ofrecer un servicio personalizado y de calidad que supere las expectativas de cada cliente, independientemente del tamaño de su negocio o proyecto.</p>
              </div>
            </Col>

            {/* Formulario */}
            <Col className='d-none d-md-flex'>
              <Form ref={form} onSubmit={sendEmail} className='bg-white bg-opacity-90 p-4 mb-3 rounded d-flex flex-column'>
                <Row>
                  <h3 style={{ fontSize: "28px" }}>Contacta conmigo</h3>
                  <Col>
                    <FormGroup className='py-3 border-rounded'>
                      <FormLabel>Nombre *</FormLabel>
                      <FormControl type='text' placeholder='Introduce tu nombre' name='nombre' />
                    </FormGroup>
                    <FormGroup className='py-3 border-rounded'>
                      <FormLabel>Email *</FormLabel>
                      <FormControl type='email' placeholder='Introduce tu dirección email' name='email' />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <FormGroup className='py-3 border-rounded'>
                      <FormLabel>Teléfono</FormLabel>
                      <FormControl type='text' placeholder='Introduce tu teléfono' name='tlf' />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <FormGroup className='py-3 border-rounded'>
                      <FormLabel>Compañía (si la hay)</FormLabel>
                      <FormControl type='text' placeholder='Nombre de tu compañía' name='cia' />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className='py-3'>
                    <FormLabel>Tema de la consulta</FormLabel>
                    <Form.Select name='tema' value={quiero}>
                      <option value="consulta">Consulta</option>
                      <option value="creacionweb">Creación web</option>
                      <option value="ecommerce">E-comerce</option>
                      <option value="Revisión de contenido">Revisión de contenido</option>
                      <option value="Mantenimiento web">Mantenimiento web</option>
                      <option value="seo">Optimización en motores de búsqueda (SEO)</option>
                      <option value="sem">Marketing en motores de búsqueda (SEM)</option>
                      <option value="marketing">Marketing digital en redes sociales</option>
                      <option value="otro">Otro</option>
                    </Form.Select>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormLabel>¿Qué necesitas?</FormLabel>
                    <Form.Control as="textarea" rows={3} placeholder='Introduce aquí tu pregunta' name='mensaje' />
                  </Col>
                </Row>

                <ReCAPTCHA sitekey={'6LcsrPckAAAAACZVtuYMEjA5SfQKmeNrMvks02rW'} ref={captchaRef} />
                <button type="submit" className={isSent ? 'btn btn-success w-100 my-4 disabled' : 'btn btn-primary w-100 my-4'}>{isSent ? 'Consulta enviada, te responderé con la mayor brevedad posible' : 'Enviar'}</button>


                <Form.Text className="text-muted">
                  Utilizaré esta información únicamente para contactarte en función de lo que necesites; no compartiré estos datos con nadie.
                </Form.Text>
              </Form>
            </Col>

          </Row>
        </Container>
      </div >
    </div >
  )

}

export default Servicios
